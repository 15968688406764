import React, { useState, useRef, useEffect } from "react";
import "./styles.css"; // Подключаем CSS

function App() {
  const [licensePlate, setLicensePlate] = useState("");
  const [mileage, setMileage] = useState("");
  const [carPhoto, setCarPhoto] = useState(null);
  const [uid, setUid] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Извлечение параметра uid из URL
    const urlParams = new URLSearchParams(window.location.search);
    const uidParam = urlParams.get("uid");
    setUid(uidParam);
  }, []);

  const handleLicensePlateChange = (event) => {
    setLicensePlate(event.target.value);
  };

  const handleMileageChange = (event) => {
    const value = event.target.value;
    // Проверяем, чтобы вводились только цифры
    if (/^\d*$/.test(value)) {
      setMileage(value);
    }
  };

  const handlePhotoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCarPhoto(event.target.files[0]);
    }
  };

  const handleCameraClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!licensePlate || !mileage) {
      alert("Пожалуйста, заполните все поля.");
      return;
    }

    if (!carPhoto) {
      alert("Пожалуйста, выберите или сделайте фото.");
      return;
    }

    const formData = new FormData();
    formData.append("licensePlate", licensePlate);
    formData.append("mileage", mileage);
    formData.append("carPhoto", carPhoto);
    
    // Отправка данных на сервер с добавлением заголовка xx-uid
    fetch("https://telebot.bbus.ru/webapp/api/v1/app-driver", {
      method: "POST",
      body: formData,
      headers: {
        'xx-uid': uid || ''  // Добавляем заголовок с uid
      }
    })
      .then((response) => response.text())  // Чтение ответа как текста
      .then((data) => {
        console.log("Success:", data);
        setShowModal(true); // Показать модальное окно
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Произошла ошибка при отправке данных.");
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.close(); // Закрыть окно после закрытия модального окна
  };

  return (
    <div className="App">
      <h1>Введите данные автомобиля</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="licensePlate">Госномер автомобиля:</label>
          <input
            type="text"
            inputMode="numeric"
            id="licensePlate"
            value={licensePlate}
            onChange={handleLicensePlateChange}
            required
          />
        </div>
        <div>
          <label htmlFor="mileage">Пробег автомобиля:</label>
          <input
            type="text"
            inputMode="numeric"
            id="mileage"
            value={mileage}
            onChange={handleMileageChange}
            required
          />
        </div>
        <div>
          <label htmlFor="carPhoto">Фото автомобиля:</label>
          <input
            type="file"
            id="carPhoto"
            ref={fileInputRef}
            accept="image/*"
            capture="environment"
            onChange={handlePhotoChange}
            style={{ display: "none" }}
          />
          <button type="button" onClick={handleCameraClick} className="camera-button">
            <span role="img" aria-label="camera">📷</span>
          </button>
          {carPhoto && <p>Фото прикреплено!</p>}
        </div>
        <button type="submit" className="submit-button">Отправить</button>
      </form>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Данные успешно отправлены!</h2>
            <p>Ваши данные были успешно отправлены на сервер.</p>
            <button onClick={handleCloseModal} className="close-button">Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
